import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { VyLoader } from '../components/common/VyLoader';
import { useEventAccess } from '../hooks/useEventAccess';
import { useSelector } from '../reduxToolkit/typedRedux';
import { getLocaleFromStaticContext } from '../utils/functions';

function Index() {
  const router = useRouter();
  const [redirectUri, setRedirectUri] = useState<string>();
  const [userLoaded, setUserLoaded] = useState(false);

  const user = useSelector(({ common }) => common.user);
  const { loadUserData } = useEventAccess();

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    router.isReady && userLoaded && checkForward();
  }, [router, userLoaded]);

  async function checkForward() {
    if (window) {
      const host = window.location.host;
      // const domains: any[] = await getCustomDomains();
      console.log('Host is ', window.location.host);
      const domains: any = {
        'event.bundesnetagentur.de': '/event/Amtsuebergabe-Praesident',
        'www.event.bundesnetagentur.de': '/event/Amtsuebergabe-Praesident',
        'electricity-bw.de': '/t/Electricity%20BW/Willkommen',
        'www.electricity-bw.de': '/t/Electricity%20BW/Willkommen',
        'www.lidl.vystem.io': '/t/Lidl%20Karriere/Lidl%20Roadshow',
        'lidl.vystem.io': '/t/Lidl%20Karriere/Lidl%20Roadshow',
        'www.festival.munich-startup.de': '/event/munich-startup-festival',
        'festival.munich-startup.de': '/event/munich-startup-festival',
      };

      const customURI = domains[host];
      const uri = customURI ?? '/login';

      // const uri = host && domains.map((e) => e.customDomain).includes(host) ? `/event/Amtsuebergabe-Praesident` : `/login`;
      if (uri) setRedirectUri(uri);
    }
  }

  useEffect(() => {
    redirectUri && router.isReady && router.push(redirectUri);
  }, [redirectUri, router]);

  const fetchUserData = async () => {
    await loadUserData();
    setUserLoaded(true);
  };

  return <VyLoader solidBackground={true} />;
}

export const getStaticProps: GetStaticProps = async (context) => {
  return {
    props: {
      ...(await serverSideTranslations(getLocaleFromStaticContext(context))),
    },
  };
};

export default Index;
